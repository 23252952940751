import styled from "styled-components";
import {
  invertColorTheme,
  LAPTOP_BREAKPOINT,
  LARGE_MARGIN,
  DARK_COLOR,
  PHONE_BREAKPOINT,
  NORMAL_MARGIN,
} from "../../../constants/LayoutConstants";
import Button from "../../Button";
import {
  TITEL_ABOUT,
  TITEL_CONTACT,
  TITEL_EXPERIENCE,
  TITEL_IMPRESSUM_DATENSCHUTZ,
  TITEL_PROJECTS,
} from "../../content/TitleContent";
import { IMPRESSUM_AND_PRIVACY_STATEMENT_PAGE_PATH } from "../../pages/PagePaths";

const BurgerMenuContainer = styled.section<{ isMenuOpen: boolean }>`
  @media (max-width: ${LAPTOP_BREAKPOINT}) {
    width: 500px;
    height: fit-content;
    /* padding-right: ${LARGE_MARGIN};
    display: flex;
    flex-direction: column;
    align-items: flex-start; */

    position: absolute;
    padding-top: 150px;
    top: -20px;
    right: 0;
    transform: ${(props) =>
      props.isMenuOpen ? "translateX(0)" : "translateX(100%)"};
    transition: transform 0.3s ease-in-out;

    cursor: pointer;
    background-color: ${DARK_COLOR};
    border-bottom-left-radius: 5%;
    z-index: -1;
  }

  @media (max-width: ${PHONE_BREAKPOINT}) {
    width: 100%;
    height: 100vh;
    border-bottom-left-radius: 0;
  }
`;

const ButtonContainer = styled.section`
  margin-left: ${NORMAL_MARGIN};
  margin-bottom: ${NORMAL_MARGIN};
  width: 60%;
  padding-right: ${LARGE_MARGIN};
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  a {
    text-align: center;
    width: 100%;
  }
`;

function BurgerMenu({ isOpen }: { isOpen: boolean }) {
  return (
    <BurgerMenuContainer isMenuOpen={isOpen}>
      <ButtonContainer>
        <Button
          label={TITEL_ABOUT}
          link={"#".concat(TITEL_ABOUT)}
          colorTheme={invertColorTheme}
        />
        <Button
          label={TITEL_PROJECTS}
          link={"#".concat(TITEL_PROJECTS)}
          colorTheme={invertColorTheme}
        />
        <Button
          label={TITEL_EXPERIENCE}
          link={"#".concat(TITEL_EXPERIENCE)}
          colorTheme={invertColorTheme}
        />
        <Button
          label={TITEL_CONTACT}
          link={"#".concat(TITEL_CONTACT)}
          colorTheme={invertColorTheme}
        />
        <Button
          label={TITEL_IMPRESSUM_DATENSCHUTZ}
          link={IMPRESSUM_AND_PRIVACY_STATEMENT_PAGE_PATH}
          colorTheme={invertColorTheme}
        />
      </ButtonContainer>
    </BurgerMenuContainer>
  );
}

export default BurgerMenu;
