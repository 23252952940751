import styled from "styled-components";
import {
  NORMAL_MARGIN,
  normalColorTheme,
} from "../../../constants/LayoutConstants";
import { TitleText } from "../../Text";
import { TITEL_VITA } from "../../content/TitleContent";
import PageWrapper from "../PageWrapper";
import VitaItem from "./VitaItem";
import { vitaContentItems } from "../../content/VitaContent";

const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  /* width: 100%; */
  text-align: center;
`;

const VitaContainer = styled.div``;

const Seperator = styled.div`
  margin: ${NORMAL_MARGIN};
`;

function Vita() {
  return (
    <PageWrapper title={TITEL_VITA} colorTheme={normalColorTheme}>
      <Container>
        {vitaContentItems.map((vitaCategory) => (
          <VitaContainer key={vitaCategory.category}>
            <TitleText text={vitaCategory.category} />
            <Seperator />
            {vitaCategory.items.map((item) => (
              <VitaItem item={item} key={item.title} />
            ))}
            <Seperator />
          </VitaContainer>
        ))}
      </Container>
    </PageWrapper>
  );
}

export default Vita;
