import Impressum from "./Impressum";
import PrivacyStatement from "./PrivaceStatement";
import PageWrapper from "../PageWrapper";
import { Divider } from "../../LayoutHelpers";
import { TITEL_IMPRESSUM_DATENSCHUTZ } from "../../content/TitleContent";
import { normalColorTheme } from "../../../constants/LayoutConstants";

function ImpressumAndPrivacyStatement() {
  return (
    <PageWrapper
      title={TITEL_IMPRESSUM_DATENSCHUTZ}
      colorTheme={normalColorTheme}
    >
      <Impressum />
      <Divider />
      <PrivacyStatement />
    </PageWrapper>
  );
}

export default ImpressumAndPrivacyStatement;
