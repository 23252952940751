import styled from "styled-components";
import {
  LARGE_MARGIN,
  NORMAL_MARGIN,
  TABLET_BREAKPOINT,
} from "../../../constants/LayoutConstants";
import {
  IProjectContentItem,
  ClickableLinkTag as CLICKABLE_LINK_TEXT,
} from "../../content/ProjectContent";
import { DescriptionText, LinkText, SubTitleText, TitleText } from "../../Text";

const ProjectContainer = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: ${LARGE_MARGIN};
`;

const ProjectInfoContainer = styled.section`
  display: flex;
  flex-direction: row;

  /* margin-top: ${LARGE_MARGIN}; */

  @media (max-width: ${TABLET_BREAKPOINT}) {
    flex-direction: column;
  }
`;
const ProjectImageContainer = styled.img`
  width: 400px;
  height: auto;
  align-self: center;
  border-radius: 3%;
  margin-right: ${LARGE_MARGIN};
  @media (max-width: ${TABLET_BREAKPOINT}) {
    margin-right: 0px;
    width: 100%;
  }
`;

const ProjectDetailContainer = styled.section``;

const Separator = styled.section`
  display: flex;
  flex-direction: column;
  margin: ${NORMAL_MARGIN} ${NORMAL_MARGIN};

  @media (max-width: ${TABLET_BREAKPOINT}) {
    text-align: center;
  }
`;

function PreviewProject({ project }: { project: IProjectContentItem }) {
  return (
    <ProjectContainer>
      <TitleText text={project.title} />
      <DescriptionText text={project.description} />
      <ProjectInfoContainer>
        <ProjectImageContainer src={project.img_filePath} />
        <ProjectDetailContainer>
          {project.categories.map((category) => {
            if (category.title === CLICKABLE_LINK_TEXT) {
              return (
                <Separator key={project.title + "_" + category.title}>
                  <SubTitleText text={category.title} />
                  <LinkText text={category.content} />
                </Separator>
              );
            } else {
              return (
                <Separator key={project.title + "_" + category.title}>
                  <SubTitleText text={category.title} />
                  <DescriptionText text={category.content} />
                </Separator>
              );
            }
          })}
          {/* {project.categories.map((category) => (
            <Separator>
              <SubTitleText text={category.title} />
              <DescriptionText text={category.content} />
            </Separator>
          ))} */}
        </ProjectDetailContainer>
      </ProjectInfoContainer>
    </ProjectContainer>
  );
}

export default PreviewProject;
