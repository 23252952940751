import styled from "styled-components";
import { normalColorTheme } from "../../../constants/LayoutConstants";
import { TITEL_EXPERIENCE } from "../../content/TitleContent";
import MainContainer from "../../MainContainer";
import ExperienceItem from "./ExperienceItem";
import { experienceContentItems } from "../../content/ExperienceContent";

const Container = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  text-align: center;
`;

function Experiences() {
  return (
    <MainContainer title={TITEL_EXPERIENCE} colorTheme={normalColorTheme}>
      <Container>
        {experienceContentItems.map((item) => (
          <ExperienceItem
            key={item.title}
            experienceContentItem={item}
            colorTheme={normalColorTheme}
          />
        ))}
      </Container>
    </MainContainer>
  );
}

export default Experiences;
