import styled from "styled-components";

import Logo from "../../Logo";
import NavMenu from "./NavMenu";

const HeaderContainer = styled.header`
  display: flex;
  flex-direction: row;
  background-color: black;
`;

function Header() {
  return (
    <HeaderContainer>
      <Logo />

      <NavMenu />
    </HeaderContainer>
  );
}

export default Header;
