import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import AllProjects from "./components/pages/AllProjects";
import Home from "./components/pages/Home";
import PageNotFound from "./components/pages/PageNotFound";
import {
  ALL_PROJECTS_PAGE_PATH,
  HOME_PAGE_PATH,
  IMPRESSUM_AND_PRIVACY_STATEMENT_PAGE_PATH,
  VITA_PAGE_PATH,
} from "./components/pages/PagePaths";
import Vita from "./components/pages/vita/Vita";
import ImpressumAndPrivacyStatement from "./components/pages/impressum_privacy_statement/ImpressumAndPrivacyStatement";
import styled from "styled-components";
import {
  DARK_COLOR,
  LIGHT_COLOR,
  normalColorTheme,
} from "./constants/LayoutConstants";
import Button from "./components/Button";
import ScrollToTop from "./components/ScrollToTop";

const Container = styled.div`
  /* display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%; */
  /* background-color: ${DARK_COLOR};
  color: ${LIGHT_COLOR}; */
`;

function App() {
  return (
    <Container>
      <Router>
        <ScrollToTop>
          <Switch>
            <Route exact path={HOME_PAGE_PATH}>
              <Home />
            </Route>
            <Route exact path={IMPRESSUM_AND_PRIVACY_STATEMENT_PAGE_PATH}>
              <ImpressumAndPrivacyStatement />
            </Route>
            <Route exact path={ALL_PROJECTS_PAGE_PATH}>
              <AllProjects />
            </Route>
            <Route exact path={VITA_PAGE_PATH}>
              <Vita />
            </Route>
            <Route>
              <PageNotFound />
            </Route>
          </Switch>
        </ScrollToTop>
      </Router>
    </Container>
  );
}

export default App;
