import { Link } from "react-router-dom";
import styled from "styled-components";
import {
  IColorTheme,
  LARGE_PADDING,
  NORMAL_PADDING,
  SMALL_PADDING,
  TABLET_BREAKPOINT,
  LARGE_MARGIN,
} from "../constants/LayoutConstants";

const ButtonContainer = styled.a<{ colorTheme: IColorTheme }>`
  position: relative;
  margin: ${LARGE_MARGIN};
  padding: ${NORMAL_PADDING} ${LARGE_PADDING};
  color: ${(props) => props.colorTheme.fg};
  border: solid 2px ${(props) => props.colorTheme.fg};
  font-size: 1.5em;
  text-decoration: none;

  transition: all 0.7s ease-in-out;

  &:hover {
    color: ${(props) => props.colorTheme.bg};
    background-color: ${(props) => props.colorTheme.fg};
  }

  /* &:before {
    width: 100%;
    height: 100%;
    position: absolute;
    content: "";
    top: 0;
    left: -100%;
    z-index: -1;
    background-color: ${(props) => props.colorTheme.fg};
    transition: all 0.2s ease-in-out;
  }

  &:hover:before {
    left: 0;
  } */

  @media (max-width: ${TABLET_BREAKPOINT}) {
    margin: 12px;
    padding: ${SMALL_PADDING} ${NORMAL_PADDING};
    font-size: 1em;
  }
`;

const LinkWrapper = styled(Link)<{ colorTheme: IColorTheme }>`
  position: relative;
  margin: ${LARGE_MARGIN};
  padding: ${NORMAL_PADDING} ${LARGE_PADDING};
  color: ${(props) => props.colorTheme.fg};
  border: solid 2px ${(props) => props.colorTheme.fg};
  font-size: 1.5em;
  text-decoration: none;

  transition: all 0.7s ease-in-out;

  &:hover {
    color: ${(props) => props.colorTheme.bg};
    background-color: ${(props) => props.colorTheme.fg};
  }

  @media (max-width: ${TABLET_BREAKPOINT}) {
    margin: 12px;
    padding: ${SMALL_PADDING} ${NORMAL_PADDING};
    font-size: 1em;
  }
`;

function Button({
  label,
  link,
  colorTheme,
}: {
  label: string;
  link: string;
  colorTheme: IColorTheme;
}) {
  if (link.includes("#")) {
    return (
      <ButtonContainer href={link} colorTheme={colorTheme}>
        {label}
      </ButtonContainer>
    );
  } else {
    return (
      <LinkWrapper to={link} colorTheme={colorTheme}>
        {label}
      </LinkWrapper>
    );
  }
}

export default Button;
