import somewhere_img from "../../assets/somewhere_flowerColorB.png";
import surec_img from "../../assets/surec.jpg";
import roboter_spinne_img from "../../assets/roboter_spinne.png";

interface IProjectCategory {
  title: string;
  content: string;
}

export interface IProjectContentItem {
  img_filePath: string;
  title: string;
  description: string;
  categories: IProjectCategory[];
}

export const ClickableLinkTag = "Link";
// {
//   img_filePath: "",
//   title: "",
//   description: "",
//   categories: [
//     {
//       title: "",
//       content: "",
//     }
//   ]
// },

export const projectContentItems: IProjectContentItem[] = [
  {
    img_filePath: somewhere_img,
    title: "somewhere",
    description: `Somewhere ist eine interaktive Webanwendung für Kinder im Alter zwischen 3-6 Jahren. In fünf liebevoll gestalteten Kapiteln begleiten Anwender:innen die Heldenreise des kleinen Hauptcharakters Unrund, der verloren geht und sich auf die Suche nach seinem Vater macht. Erzählt wird die Geschichte mittels Illustrationen und interaktiven 3D Szenen, die von einem Orchester musikalisch untermalt sind.`,
    categories: [
      {
        title: "Auftraggeber",
        content: "Filmuniversität Babelsberg KONRAD WOLF",
      },
      {
        title: "Zeitraum",
        content: "Sep. 2020 – April 2021",
      },
      {
        title: "Aufgaben",
        content:
          "Entwicklung interaktiver 3D Szenen, Optimierung, Refaktorierung, Bugfixing,  Buildsysteme",
      },

      {
        title: "Technologien",
        content: "JavaScript, Babylonjs, Github",
      },
      {
        title: "Link",
        content: "https://somewhere.gl",
      },
    ],
  },
  {
    img_filePath: surec_img,
    title: "surec",
    description:
      "surec ist eine Open-Source App für Android und IOS, die es Anwender:innen ermöglicht Belege in Form von Notiz, Foto, Audio und Video rechtssicher und verifizierbar zu erfassen, zu dokumentieren und digitalisiert weiterzugeben. Somit sollen papierlastige Vorgänge bei gesellschaftlichen Organisationen z.B. in den Bereichen Diskriminierung, Miete, Arbeit oder Verbraucherschutz reduziert, strukturiert und erleichtert werden. Dieses Projekt wurde von dem Prototype Fond und Bundesministerium für Bildung und Forschung unterstützt und gefördert.",
    categories: [
      {
        title: "Auftraggeber",
        content: "Richard Meinsen und Christoph Haufe GbR",
      },
      {
        title: "Zeitraum",
        content: "Sep. 2020 – April 2021",
      },
      {
        title: "Aufgaben",
        content: "Gestaltung, Konzeptionierung, Entwicklung",
      },
      {
        title: "Technologien",
        content: "Dart, Flutter, Jira, Confluencer, Bitbucket, AdobeXR",
      },
      {
        title: ClickableLinkTag,
        content: "https://surec.eu",
      },
    ],
  },
  {
    img_filePath: roboter_spinne_img,
    title: "Halloween-Spinne",
    description:
      "Die Roboter-Spinne wurde für eine Halloween-Promotion-Aktion verwendet. Bei diesem Auftrag bestand die Herausforderung darin, mich innerhalb von zwei Tagen einzuarbeiten, die Möglichkeiten der Spinne auszuloten und ein ansehnliches Resultat zu produzieren.",
    categories: [
      {
        title: "Auftraggeber",
        content: "dan pearlman",
      },
      {
        title: "Zeitraum",
        content: "Sept. 2019",
      },
      {
        title: "Aufgaben",
        content: "Konzeptionierung der Interaktion, Implementierung",
      },
      {
        title: "Technologien",
        content: "Lua, Roboter-Spinne T8X",
      },
    ],
  },
];
