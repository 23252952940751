export interface IVitaCategory {
  category: string;
  items: IVitaContentItem[];
}

export interface IVitaContentItem {
  year: string;
  title: string;
  description: string;
}

export const vitaContentItems: IVitaCategory[] = [
  {
    category: "Arbeitserfahrung",
    items: [
      {
        year: "Sep. 2020 - Apr. 2021",
        title: "Filmuniversität Babelsberg KONRAD WOLF",
        description:
          "Implementierung von Interaktionen und Animationen für ein interaktives 3D-Kinderbuch in JavaScript mit dem Framework Babylonjs.",
      },
      {
        year: "Sep. 2020 - Apr. 2021",
        title: "Richard Meinsen und Christoph Haufe GbR",
        description:
          "Konzeptionierung und Entwicklung einer App in Dart mit dem Framework Flutter.",
      },
      {
        year: "Feb. 2020",
        title: "BeamXR",
        description: "Software Consulting",
      },
      {
        year: "Dez. 2019 - Apr. 2020",
        title: "Bredex",
        description:
          "Schulungsleiter/Mentor für Java, JavaScript und PHP-Programmierung",
      },
      {
        year: "Sep. 2019",
        title: "dan pearlman",
        description: "Implementierung einer Roboter-Spinne (T8X) in Lua",
      },
      {
        year: "2014 - 2018",
        title: "Ovidius GmbH",
        description:
          "Konzeptionierung, Entwicklung und Dokumentation von manuellen Testszenarien, Unit Tests, automatisierten GUI Tests für Web und WPF Anwendungen, Testumgebungen für Konsolen, Web und WPF Anwendungen, Plugins zur Anbindung von Testmanagmentsoftware",
      },
      {
        year: "2014 - 2016",
        title: "Die Gelbe Villa",
        description:
          "Kreativ- und Bildungszentrum für Kinder und Jugendliche freiberuflich Tätigkeit in der Entwicklung, Gestaltung und Leitung von Workshops für Programmiergrundlagen in Java, Grafik-Programmierung mit Processing und kreativen Programmierrunden für Kinder von 11 bis 14 Jahre",
      },
      {
        year: "2009 - 2012",
        title: "Freyer & Siegel Elektronik GmbH & Co. KG",
        description:
          "Planung, Konzeptionierung und Entwicklung von Bedien-, System- und Verfahrensprozessen für Embedded-Systeme. Softwareentwicklung und -dokumentation nach DIN EN 62304, Erarbeitung und Entwicklung von Softwareanforderungen mit dem Kunden, Präsentation von Softwarekonzepten beim Kunden",
      },
      {
        year: "2009",
        title: "Charité, Universitätsmedizin Berlin",
        description:
          "freiberufliche Tätigkeit im Hard- und Software Support. Softwareentwicklung für Pocket PCs Softwareentwicklung einer Auswertungsapplikation für psychologische und physiologische Messdaten",
      },
      {
        year: "2007 - 2008",
        title: "Fraunhofer Institut FIRST",
        description:
          "Diplomand im Bereich Embedded-Systems. Konzeptionierung, Entwicklung und Test eines benutzerorientierten und komponentenbasierten Managementsystems zur Anbindung von DAB Systemen (Digital Audio Broadcasting)",
      },
      {
        year: "2006 - 2007",
        title: "Fraunhofer Institut IPK",
        description:
          "Praktikum im Bereich Produktionssysteme Konzeptionierung und Entwicklung von Maschinenprogrammen für die Bearbeitung von Schneidwerkzeugen mittels Laser, Entwicklung einer Auswertungssoftware für Schnittkraftdaten",
      },
      {
        year: "2005- 2006",
        title: "Ingenieurbüro IBS",
        description:
          "Studentische Hilfskraft im Bereich Softwareentwicklung Entwicklung von Gerätetreibern",
      },
    ],
  },
  {
    category: "Ausbildung",
    items: [
      {
        year: "März - Juli 2019",
        title: "Future Training & Consulting GmbH",
        description:
          "Schulung in Unreal Engine und Unity mit dem Schwerpunkt Augmented Reality",
      },
      {
        year: "2014 - 2016",
        title: "Humboldt Universität in Berlin",
        description:
          "Studiengang Erziehungswissenschaften und Philosophie (nebenberuflich)",
      },
      {
        year: "2000 - 2003",
        title: "Technische Berufsfachschule Lette-Verein",
        description:
          "Ausbildung zum Technischen Assistent für Elektronik und Datentechnik mit bestandenen Fachabitur",
      },
      {
        year: "2003 - 2008",
        title:
          "Technische Fachhochschule Berlin (heute BEUTH Hochschule für Technik)",
        description:
          "Studiengang Technische Informatik, Spezialisierung auf Echtzeitsysteme mit erfolgreichem Abschluss als Diplom-Ingenieur ( FH )",
      },
    ],
  },
];
