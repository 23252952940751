import styled from "styled-components";
import {
  IColorTheme,
  invertColorTheme,
  LARGE_MARGIN,
} from "../../constants/LayoutConstants";
import { footerContent } from "../content/FooterContent";
import { DescriptionText } from "../Text";

const Container = styled.section<{ colorTheme: IColorTheme }>`
  padding-bottom: ${LARGE_MARGIN};
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${(props) => props.colorTheme.bg};
  color: ${(props) => props.colorTheme.fg};
`;

function Footer() {
  return (
    <Container colorTheme={invertColorTheme}>
      {footerContent.copyright.map((text, index) => {
        return (
          <DescriptionText key={"footer_description_" + index} text={text} />
        );
      })}
    </Container>
  );
}

export default Footer;
