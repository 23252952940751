import About from "../sections/About";
import Contact from "../sections/contact/Contact";
import Experiences from "../sections/experience/Experiences";
import Footer from "../sections/Footer";
import Header from "../sections/header/Header";
import Projects from "../sections/projects/Projects";
import { DecorateContainer } from "../LayoutHelpers";

function Home() {
  return (
    <>
      <Header />
      <DecorateContainer isTopContainer={true} />
      <About />
      <DecorateContainer isTopContainer={false} />
      <Projects />
      <DecorateContainer isTopContainer={true} />
      <Experiences />
      <DecorateContainer isTopContainer={false} />
      <Contact />
      <Footer />
    </>
  );
}

export default Home;
