import {
  faLaptopCode,
  IconDefinition,
} from "@fortawesome/free-solid-svg-icons";
import { faBook } from "@fortawesome/free-solid-svg-icons";

interface IExperienceCategory {
  title: string;
  content: string;
}

export interface IExperienceContentItem {
  icon: IconDefinition;
  title: string;
  description: string[];
  categories: IExperienceCategory[];
}

export const experienceContentItems: IExperienceContentItem[] = [
  {
    icon: faLaptopCode,
    title: "Softwareentwickler",
    description: [
      `
    Ich habe mich schon als Kind für Computer und deren Funktion interessiert, 
    daher war für mich relativ schnell klar was ich später mal werden würde. 
    Nach meiner Ausbildung als Elektroniker und Datentechniker habe ich mein Studium als Diplom-Ingenieur 
    für Technische Informatik mit der Spezialisierung auf Echtzeitsysteme erfolgreich abgeschlossen.`,

      `Mittlerweile habe ich mehrere Jahre Berufserfahrungen in den unterschiedlichsten Unternehmen und Bereichen gesammelt. 
    Somit konnte ich meine Erfahrungen in Softwareentwicklungs- und Unternehmensprozessen, Entwicklungstools, 
    Entwicklungsstrategien und Programmiersprachen stetig erweitern.     
      `,
    ],
    categories: [
      {
        title: "Bereiche:",
        content: `App, Web, 3D, Augmented-Reality, Testautomatisierung, Embedded-Systeme, Cloud-Architektur, 
         agile Prozesse`,
      },
      {
        title: "Programmiersprachen:",
        content: "Flutter, C#, C, Java, JavaScript, HTML, CSS",
      },
      {
        title: "Tools:",
        content:
          "Unity, Processing, Git, Jira, Confluencer, Subversion, Jenkins, Team Foundation Server",
      },
    ],
  },
  {
    icon: faBook,
    title: "Schulungsleiter und Mentor",
    description: [
      `Während einer Umstrukturierungsphase in meinem Leben, habe ich mich mit den Themen Bildung und Erziehung auseinandergesetzt und angefangen Erziehungswissenschaften und Philosophie zu studieren. 
    Ich fing an mich mit unterschiedlichen Lerntypen und ~prozessen auseinander zu setzen und hinterfragte mein eigenes Lernverhalten. 
    Somit ergab sich für mich eine neue Perspektive auf das Lernen sowie das Interesse daran anderen bei ihrem Lernprozess zu begleiten und zu unterstützen.`,

      `Lernen ist für mich ein nie abgeschlossener Prozess in unserem Leben, der Spaß machen sollte und zu spannenden
    Verknüpfung zwischen den unterschiedlichsten Interessensbereichen führen kann. Diese interdisziplinäre Verknüpfung führt zu Innovation und bereichert unsere Gesellschaft.
    `,
      ` Ich sehe meine Tätigkeit nicht nur in der Wissensvermittlung, sondern auch im Bereich der Interessensfindung des Lernenden. 
      Denn eine intrinsische Motivation ist der Antrieb den es benötigt, um nachhaltig und mit Freude zu lernen. Diese Motivation trägt jeder in sich, sie muss nur gefunden werden.`,
    ],
    categories: [
      {
        title: "Erfahrungen:",
        content: `
          Konzeptionierung und Leitung von Informatik Workshops in der Kinderbildung sowie
          Informatik Trainer und Projekt-Mentor in der Erwachsenenbildung
        `,
      },
    ],
  },
];
