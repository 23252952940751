import styled from "styled-components";
import profileImage from "../../assets/profil.png";
import {
  LARGE_CONTENT_WIDTH,
  NORMAL_CONTENT_WIDTH,
  SMALL_CONTENT_WIDTH,
  normalColorTheme,
  LARGE_MARGIN,
  NORMAL_MARGIN,
} from "../../constants/LayoutConstants";

import Button from "../Button";
import MainContainer from "../MainContainer";
import { AboutContent } from "../content/AboutContent";
import { TITEL_ABOUT, TITEL_VITA } from "../content/TitleContent";
import { VITA_PAGE_PATH } from "../pages/PagePaths";
import { DescriptionText, TitleText } from "../Text";

const Container = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ProfileImage = styled.img`
  width: 300px;
  margin-bottom: ${LARGE_MARGIN};
  src: ${(props) => props.src};
  border-radius: 50%;
  border-color: black;
  box-shadow: 10px 10px 15px gray;
`;

const TextWrapper = styled.section`
  width: ${LARGE_CONTENT_WIDTH};
  margin: ${NORMAL_MARGIN};
  text-align: center;

  @media (max-width: 800px) {
    width: ${NORMAL_CONTENT_WIDTH};
  }
  @media (max-width: 600px) {
    width: ${SMALL_CONTENT_WIDTH};
  }
`;

function About() {
  return (
    <MainContainer title={TITEL_ABOUT} colorTheme={normalColorTheme}>
      <Container>
        <ProfileImage src={profileImage} />
        <TitleText text={AboutContent.title} />
        {AboutContent.description.map((pItem, index) => (
          <TextWrapper key={"about_text_p_" + index}>
            <DescriptionText text={pItem} />
          </TextWrapper>
        ))}
        <Button
          colorTheme={normalColorTheme}
          label={TITEL_VITA}
          link={VITA_PAGE_PATH}
        />
      </Container>
    </MainContainer>
  );
}

export default About;
