import styled from "styled-components";
import { normalColorTheme } from "../../constants/LayoutConstants";
import { TitleText } from "../Text";
import { TITEL_ALL_PROJECTS } from "../content/TitleContent";
import PageWrapper from "./PageWrapper";

const VitaContainer = styled.section`
  width: 100%;
  height: 500px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

function AllProjects() {
  return (
    <PageWrapper title={TITEL_ALL_PROJECTS} colorTheme={normalColorTheme}>
      <VitaContainer>
        <TitleText text="Ist in arbeit ..." />
      </VitaContainer>
    </PageWrapper>
  );
}

export default AllProjects;
