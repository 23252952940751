import { IconDefinition } from "@fortawesome/fontawesome-common-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import { LIGHT_COLOR, LARGE_MARGIN } from "../../../constants/LayoutConstants";

const Container = styled.a`
  width: fit-content;
  margin: ${LARGE_MARGIN};
  color: ${LIGHT_COLOR};
`;

function SocialMediaItem({
  icon,
  link,
}: {
  icon: IconDefinition;
  link: string;
}) {
  return (
    <Container href={link}>
      <FontAwesomeIcon icon={icon} size="2x"></FontAwesomeIcon>
    </Container>
  );
}

export default SocialMediaItem;
