import { ReactNode } from "react";
import styled from "styled-components";
import { IColorTheme } from "../constants/LayoutConstants";
import { HeadlineText } from "./Text";

const Container = styled.section<{ colorTheme: IColorTheme }>`
  width: 100%;

  display: flex;
  flex-direction: column;
  color: ${(props) => props.colorTheme.fg};
  background-color: ${(props) => props.colorTheme.bg};
`;

function MainContainer({
  title,
  colorTheme,
  children,
}: {
  title: string;
  children: ReactNode;
  colorTheme: IColorTheme;
}) {
  return (
    <Container colorTheme={colorTheme} id={title}>
      <HeadlineText title={title} colorTheme={colorTheme} />
      {children}
    </Container>
  );
}

export default MainContainer;
