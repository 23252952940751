import styled from "styled-components";
import { invertColorTheme } from "../../../constants/LayoutConstants";
import MainContainer from "../../MainContainer";
import Logo from "../../Logo";
import {
  faLinkedin,
  faXing,
  faGithub,
} from "@fortawesome/free-brands-svg-icons";
import SocialMediaItem from "./SocialMediaItem";
import { TITEL_CONTACT } from "../../content/TitleContent";
import { DescriptionText } from "../../Text";
import { GITHUB_LINK, LINKEDIN_LINK, XINK_LINK } from "../../content/Links";
import { EMAIL, TEL } from "../../content/ContactContent";

// import {fa-} from "@fortawesome/";
const ContactContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: start; */
`;

const SocialMedia = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

function Contact() {
  return (
    <MainContainer title={TITEL_CONTACT} colorTheme={invertColorTheme}>
      <ContactContainer>
        <Logo />
        <DescriptionText text={"email: " + EMAIL} />
        <DescriptionText text={"Telefon: " + TEL} />
        <SocialMedia>
          <SocialMediaItem
            icon={faLinkedin}
            link={LINKEDIN_LINK}
          ></SocialMediaItem>
          <SocialMediaItem icon={faXing} link={XINK_LINK}></SocialMediaItem>
          <SocialMediaItem icon={faGithub} link={GITHUB_LINK}></SocialMediaItem>
        </SocialMedia>
      </ContactContainer>
    </MainContainer>
  );
}

export default Contact;
