import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import ButtonMenu from "./ButtonMenu";
import BurgerMenu from "./BurgerMenu";
import {
  LIGHT_COLOR,
  LAPTOP_BREAKPOINT,
  LARGE_MARGIN,
} from "../../../constants/LayoutConstants";
import { useHistory } from "react-router";

const NavMenuContainer = styled.section`
  width: 100%;
  margin-right: ${LARGE_MARGIN};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

const BurgerMenuIconContainer = styled.section`
  display: none;

  @media (max-width: ${LAPTOP_BREAKPOINT}) {
    margin: ${LARGE_MARGIN};
    display: flex;

    z-index: 1;
  }
`;

function NavMenu() {
  const [isMenuOpen, toggleMenu] = useState(false);
  const history = useHistory();

  let icon = isMenuOpen ? faTimes : faBars;

  useEffect(() => {
    return history.listen(() => {
      toggleMenu(false);
    });
  }, [history]);

  return (
    <NavMenuContainer>
      <ButtonMenu />
      <BurgerMenuIconContainer>
        <BurgerMenu isOpen={isMenuOpen} />
        <FontAwesomeIcon
          icon={icon}
          size="3x"
          color={LIGHT_COLOR}
          onClick={() => toggleMenu(!isMenuOpen)}
        />
      </BurgerMenuIconContainer>
    </NavMenuContainer>
  );
}

export default NavMenu;
