import styled from "styled-components";
import {
  LARGE_MARGIN,
  LARGE_PADDING,
  NORMAL_MARGIN,
  NORMAL_PADDING,
  PHONE_BREAKPOINT,
  SMALL_MARGIN,
  SMALL_PADDING,
} from "../../../constants/LayoutConstants";
import { IVitaContentItem } from "../../content/VitaContent";
import { DescriptionText, SubTitleText, TitleText } from "../../Text";

const VitaItemContainer = styled.section`
  width: 100%;
  display: flex;
  flex-direction: row;
  text-align: start;
`;

const YearContainer = styled.div`
  width: 40%;
  padding-right: ${LARGE_PADDING};
  display: flex;
  justify-content: flex-end;

  @media (max-width: ${PHONE_BREAKPOINT}) {
    padding-right: ${SMALL_MARGIN};
  }
`;

const ContentContainer = styled.div`
  width: 60%;
  padding-left: ${LARGE_PADDING};
  padding-bottom: ${LARGE_PADDING};
  display: flex;
  flex-direction: column;
  border-left: 2px solid black;

  @media (max-width: ${PHONE_BREAKPOINT}) {
    padding-left: ${SMALL_MARGIN};
    padding-bottom: ${SMALL_PADDING};
  }
`;

function VitaItem({ item }: { item: IVitaContentItem }) {
  return (
    <VitaItemContainer>
      <YearContainer>
        <SubTitleText text={item.year} />
      </YearContainer>
      <ContentContainer>
        <SubTitleText text={item.title} />
        <DescriptionText text={item.description} />
      </ContentContainer>
    </VitaItemContainer>
  );
}

export default VitaItem;
