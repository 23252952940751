interface IAboutContent {
  title: string;
  description: string[];
}

export const AboutContent: IAboutContent = {
  title: "Hallo, mein Name ist Christoph Haufe.",
  description: [
    `Ich bin ein kreativer und kommunikativer Menschen, der sich immer wieder mit neuen und innovativen Technologien und Trends beschäftigt,
    auseinandersetzt und keine Angst davor hat sich in komplexe Themengebiete einzuarbeiten.` ,
    `Meine Leidenschaft umfasst die Bereiche Technologie, Kunst und Erziehungswissenschaften. 
    Diese Bereiche versuche ich in meinen Projekten miteinander verschmelzen zu lassen.`,

    `Eines meiner Leitmotive ist, was ich nicht kann, kann ich lernen.`,
  ],
};
