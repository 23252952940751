import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  IColorTheme,
  LARGE_PADDING,
  NORMAL_PADDING,
  SMALL_CONTENT_WIDTH,
  TABLET_BREAKPOINT,
  LARGE_MARGIN,
} from "../../../constants/LayoutConstants";
import { IExperienceContentItem } from "../../content/ExperienceContent";
import { DescriptionText, SubTitleText, TitleText } from "../../Text";

const ExperienceContainer = styled.section<{ colorTheme: IColorTheme }>`
  width: 500px;
  margin: ${LARGE_MARGIN};
  padding: ${LARGE_PADDING};
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 3px solid ${(props) => props.colorTheme.fg};
  border-radius: 5%;

  @media (max-width: ${TABLET_BREAKPOINT}) {
    width: ${SMALL_CONTENT_WIDTH};
    border-radius: 0;
    padding: ${NORMAL_PADDING};
  }
`;

const DetailContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

function ExperienceItem({
  experienceContentItem,
  colorTheme,
}: {
  experienceContentItem: IExperienceContentItem;
  colorTheme: IColorTheme;
}) {
  return (
    <ExperienceContainer colorTheme={colorTheme}>
      <FontAwesomeIcon icon={experienceContentItem.icon} size="3x" />
      <TitleText text={experienceContentItem.title} />
      {experienceContentItem.description.map((text, index) => (
        <DescriptionText key={"description_" + index} text={text} />
      ))}

      {experienceContentItem.categories.map((category) => (
        <DetailContainer key={"category_" + category.title}>
          <SubTitleText text={category.title} />
          <DescriptionText text={category.content} />
        </DetailContainer>
      ))}
    </ExperienceContainer>
  );
}

export default ExperienceItem;
