export const LAPTOP_BREAKPOINT = "1400px";
export const TABLET_BREAKPOINT = "900px";
export const PHONE_BREAKPOINT = "600px";

export const LARGE_CONTENT_WIDTH = "800px";
export const NORMAL_CONTENT_WIDTH = "600px";
export const SMALL_CONTENT_WIDTH = "300px";

export const XLARGE_MARGIN = "32px";
export const LARGE_MARGIN = "24px";
// export const Y_LARGE_SECTION_MARGIN = "32px";

export const NORMAL_MARGIN = "16px";
export const SMALL_MARGIN = "8px";
// export const Y_INNER_SECTION_MARGIN = "8px";

export const LARGE_PADDING = "16px";
export const NORMAL_PADDING = "8px";
export const SMALL_PADDING = "4px";

// colors
export const LIGHT_COLOR = "#ffffff";
export const DARK_COLOR = "#000000";

export interface IColorTheme {
  bg: string;
  fg: string;
}

export const normalColorTheme = {
  bg: LIGHT_COLOR,
  fg: DARK_COLOR,
};

export const invertColorTheme = {
  bg: DARK_COLOR,
  fg: LIGHT_COLOR,
};
