import styled from "styled-components";
import { TitleText } from "../Text";

const Container = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
`;

function PageNotFound() {
  return (
    <Container>
      <TitleText text="Oops Page not found!" />
    </Container>
  );
}

export default PageNotFound;
