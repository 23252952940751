import styled from "styled-components";
import { NORMAL_MARGIN } from "../constants/LayoutConstants";

import logoImageAsPng from "../assets/logo_v7.png";

const ImageContainer = styled.img`
  width: 100px;
  height: 100px;
  margin: ${NORMAL_MARGIN};
  border-radius: 50%;
  background-color: white;
  padding: 15px;
  src: ${(props) => props.src};
`;

function Logo() {
  return <ImageContainer src={logoImageAsPng}></ImageContainer>;
}

export default Logo;
