import styled from "styled-components";
import MainContainer from "../../MainContainer";
import {
  invertColorTheme,
  LARGE_CONTENT_WIDTH,
  NORMAL_CONTENT_WIDTH,
  SMALL_CONTENT_WIDTH,
  TABLET_BREAKPOINT,
  PHONE_BREAKPOINT,
  LARGE_MARGIN,
  NORMAL_MARGIN,
} from "../../../constants/LayoutConstants";
import Button from "../../Button";
import PreviewProject from "./PreviewProjects";
import { TITEL_ALL_PROJECTS, TITEL_PROJECTS } from "../../content/TitleContent";
import { projectContentItems } from "../../content/ProjectContent";
import { ALL_PROJECTS_PAGE_PATH } from "../../pages/PagePaths";
import { Divider } from "../../LayoutHelpers";

const ProjectsContainer = styled.section`
  width: ${LARGE_CONTENT_WIDTH};
  margin-top: ${LARGE_MARGIN};

  align-self: center;

  @media (max-width: ${TABLET_BREAKPOINT}) {
    width: ${NORMAL_CONTENT_WIDTH};
    margin-top: ${NORMAL_MARGIN};
  }
  @media (max-width: ${PHONE_BREAKPOINT}) {
    width: ${SMALL_CONTENT_WIDTH};
  }
`;

const ButtonContainer = styled.div`
  align-self: center;
  margin-top: ${LARGE_MARGIN};
  margin-bottom: ${LARGE_MARGIN};
`;

function Projects() {
  return (
    <MainContainer title={TITEL_PROJECTS} colorTheme={invertColorTheme}>
      <ProjectsContainer>
        {projectContentItems.map((projectItem, idx) => {
          if (idx < projectContentItems.length - 1) {
            return (
              <div key={projectItem.title}>
                <PreviewProject project={projectItem}></PreviewProject>
                <Divider key={"divider_" + projectItem.title} />
              </div>
            );
          } else {
            return (
              <PreviewProject
                key={projectItem.title}
                project={projectItem}
              ></PreviewProject>
            );
          }
        })}
      </ProjectsContainer>
      {/* <ButtonContainer>
        <Button
          label={TITEL_ALL_PROJECTS}
          link={ALL_PROJECTS_PAGE_PATH}
          colorTheme={invertColorTheme}
        ></Button>
      </ButtonContainer> */}
    </MainContainer>
  );
}

export default Projects;
