import styled from "styled-components";
import {
  LARGE_MARGIN,
  NORMAL_MARGIN,
  TABLET_BREAKPOINT,
} from "../constants/LayoutConstants";

const Container = styled.div<{ isTopContainer: boolean }>`
  width: 100%;
  height: 75px;

  // hack to fix layout issue under chrome
  position: relative;
  ${(props) => (props.isTopContainer ? " bottom: 1px;" : " bottom: -1px;")};

  background-color: black;
  ${(props) =>
    props.isTopContainer
      ? "clip-path: polygon(100% 0, 0 0, 100% 100%);"
      : "clip-path: polygon(0 0, 0 100%, 100% 100%);"}

  @media (max-width: 600px) {
    height: 40px;
  }
`;

export const Divider = styled.hr`
  width: 100%;
  margin-top: ${LARGE_MARGIN};
  @media (max-width: ${TABLET_BREAKPOINT}) {
    margin-top: ${NORMAL_MARGIN};
  }
`;

export function DecorateContainer({
  isTopContainer,
}: {
  isTopContainer: boolean;
}) {
  return <Container isTopContainer={isTopContainer} />;
}
