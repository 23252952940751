import styled from "styled-components";
import { faArrowCircleLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  DARK_COLOR as DARK_COLOR,
  invertColorTheme,
  IColorTheme,
  LARGE_CONTENT_WIDTH,
  normalColorTheme,
  NORMAL_CONTENT_WIDTH,
  NORMAL_MARGIN,
  SMALL_CONTENT_WIDTH,
} from "../../constants/LayoutConstants";
import Footer from "../sections/Footer";
import { DecorateContainer } from "../LayoutHelpers";
import Contact from "../sections/contact/Contact";
import { ReactNode } from "react";
import MainContainer from "../MainContainer";
import { TITEL_IMPRESSUM_DATENSCHUTZ } from "../content/TitleContent";
import { HOME_PAGE_PATH } from "./PagePaths";

const ContentContainer = styled.div`
  display: flex;

  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const NavContainer = styled.section`
  height: 100px;
  width: 100%;
  background-color: ${DARK_COLOR};
`;

const IconBackContainer = styled.a<{ colorTheme: IColorTheme }>`
  position: absolute;
  top: ${NORMAL_MARGIN};
  left: ${NORMAL_MARGIN};
  border: 2px solid ${(props) => props.colorTheme.bg};
  border-radius: 50%;
  background-color: ${(props) => props.colorTheme.bg};
  color: ${(props) => props.colorTheme.fg};
  transition: all 0.7s ease-in-out;

  &:hover {
    background-color: ${(props) => props.colorTheme.fg};
    color: ${(props) => props.colorTheme.bg};
  }
`;

const ContentContainerWrapper = styled.section`
  display: flex;

  flex-direction: column;
  width: ${LARGE_CONTENT_WIDTH};
  margin: ${NORMAL_MARGIN};

  @media (max-width: 800px) {
    width: ${NORMAL_CONTENT_WIDTH};
  }
  @media (max-width: 600px) {
    width: ${SMALL_CONTENT_WIDTH};
  }
`;

function PageWrapper({
  title,
  colorTheme,
  children,
}: {
  title: string;
  colorTheme: IColorTheme;
  children: ReactNode;
}) {
  return (
    <>
      <NavContainer>
        <IconBackContainer colorTheme={normalColorTheme} href={HOME_PAGE_PATH}>
          <FontAwesomeIcon icon={faArrowCircleLeft} size="3x" />
        </IconBackContainer>
      </NavContainer>
      <DecorateContainer isTopContainer={true} />
      <MainContainer title={title} colorTheme={colorTheme}>
        <ContentContainer>
          <ContentContainerWrapper>{children}</ContentContainerWrapper>
        </ContentContainer>
      </MainContainer>
      <DecorateContainer isTopContainer={false} />
      <Contact />
      <Footer />
    </>
  );
}

export default PageWrapper;
