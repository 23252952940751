import styled from "styled-components";
import {
  invertColorTheme,
  LAPTOP_BREAKPOINT,
} from "../../../constants/LayoutConstants";
import Button from "../../Button";
import {
  TITEL_ABOUT,
  TITEL_CONTACT,
  TITEL_IMPRESSUM_DATENSCHUTZ,
  TITEL_PROJECTS,
  TITEL_EXPERIENCE,
} from "../../content/TitleContent";
import { IMPRESSUM_AND_PRIVACY_STATEMENT_PAGE_PATH } from "../../pages/PagePaths";

const ButtonMenuContainer = styled.section`
  display: flex;
  flex-direction: row;
  @media (max-width: ${LAPTOP_BREAKPOINT}) {
    display: none;
  }
`;

function ButtonMenu() {
  return (
    <ButtonMenuContainer>
      <Button
        label={TITEL_ABOUT}
        link={"#".concat(TITEL_ABOUT)}
        colorTheme={invertColorTheme}
      />
      <Button
        label={TITEL_PROJECTS}
        link={"#".concat(TITEL_PROJECTS)}
        colorTheme={invertColorTheme}
      />
      <Button
        label={TITEL_EXPERIENCE}
        link={"#".concat(TITEL_EXPERIENCE)}
        colorTheme={invertColorTheme}
      />
      <Button
        label={TITEL_CONTACT}
        link={"#".concat(TITEL_CONTACT)}
        colorTheme={invertColorTheme}
      />
      <Button
        label={TITEL_IMPRESSUM_DATENSCHUTZ}
        link={IMPRESSUM_AND_PRIVACY_STATEMENT_PAGE_PATH}
        colorTheme={invertColorTheme}
      />
    </ButtonMenuContainer>
  );
}

export default ButtonMenu;
