import styled from "styled-components";
import {
  IColorTheme,
  TABLET_BREAKPOINT,
  PHONE_BREAKPOINT,
  LARGE_MARGIN,
  NORMAL_MARGIN,
  LIGHT_COLOR,
  SMALL_MARGIN,
} from "../constants/LayoutConstants";

const xSmallTextSize: string = "0.8em";
const smallTextSize: string = "0.9em";
const normalTextSize: string = "1.1em";
const largeTextSize: string = "1.6em";
const xLargeTextSize: string = "2em";

const TitleStyle = styled.span`
  font-size: ${largeTextSize};
  font-weight: bold;
  text-align: center;
  margin-bottom: ${NORMAL_MARGIN};

  @media (max-width: ${TABLET_BREAKPOINT}) {
    font-size: ${normalTextSize};
  }
  @media (max-width: ${PHONE_BREAKPOINT}) {
    font-size: ${smallTextSize};
  }
`;

const SubTitleStyle = styled.span`
  font-size: ${normalTextSize};
  font-weight: bold;
  margin-bottom: ${SMALL_MARGIN};
  @media (max-width: ${TABLET_BREAKPOINT}) {
    font-size: ${smallTextSize};
    /* text-align: center; */
    margin: 0;
  }
`;

const DescriptionTextStyle = styled.span`
  font-size: ${normalTextSize};
  margin-bottom: ${NORMAL_MARGIN};

  @media (max-width: ${TABLET_BREAKPOINT}) {
    font-size: ${smallTextSize};
    margin-bottom: ${SMALL_MARGIN};
  }
  @media (max-width: ${PHONE_BREAKPOINT}) {
    font-size: ${xSmallTextSize};
  }
`;

const LinkTextStyle = styled.a`
  font-size: ${normalTextSize};
  margin-bottom: ${SMALL_MARGIN};
  text-decoration: none;
  color: ${LIGHT_COLOR};

  @media (max-width: ${TABLET_BREAKPOINT}) {
    font-size: ${smallTextSize};
  }
  @media (max-width: ${PHONE_BREAKPOINT}) {
    font-size: ${xSmallTextSize};
  }
`;

const HeadLineStyle = styled.span<{ borderColor: string }>`
  margin-left: ${LARGE_MARGIN};
  margin-bottom: ${LARGE_MARGIN};
  letter-spacing: 3px;
  font-size: ${xLargeTextSize};
  font-weight: bold;

  @media (max-width: ${TABLET_BREAKPOINT}) {
    font-size: ${largeTextSize};
  }

  @media (max-width: ${PHONE_BREAKPOINT}) {
    margin-bottom: ${NORMAL_MARGIN};
    font-size: ${normalTextSize};
    text-align: center;
    margin-left: 0;
  }
`;

export function HeadlineText({
  title: text,
  colorTheme,
}: {
  title: string;
  colorTheme: IColorTheme;
}) {
  return (
    <HeadLineStyle borderColor={colorTheme.fg}>
      {text.toUpperCase()}
    </HeadLineStyle>
  );
}

export function TitleText({ text }: { text: string }) {
  return <TitleStyle>{text}</TitleStyle>;
}

export function SubTitleText({ text }: { text: string }) {
  return <SubTitleStyle>{text}</SubTitleStyle>;
}

export function DescriptionText({ text }: { text: string }) {
  return <DescriptionTextStyle>{text}</DescriptionTextStyle>;
}

export function LinkText({ text }: { text: string }) {
  return <LinkTextStyle href={text}>{text}</LinkTextStyle>;
}
